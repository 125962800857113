<template>
    <v-dialog :value="value" @input="$emit('input', $event.target ? $event.target.value : false)" max-width="600">
        <v-card tile elevation="4" class="pa-0" max-width="600">
            <v-toolbar short flat color="white">
                <v-toolbar-title class="teal--text">Create New Account</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="px-5">
            <v-form @submit.prevent="onSubmitForm" @keyup.enter.native.prevent="onSubmitForm">
                <v-text-field
                    ref="newAccountNameInput"
                    v-model="newAccountName"
                    label="Account Name"
                    :rules="newAccountNameRules"
                    validate-on-blur
                    color="teal"
                    required
                    hint="The name for the account"
                    type="text"
                    outlined
                >
                </v-text-field>
            </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="4" class="teal white--text" @click="onSubmitForm" :disabled="!isFormComplete">
                    <span>Create</span>
                </v-btn>
                <v-btn text color="grey" @click="cancel">
                    <span>Cancel</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { isValidName, compact } from '@/sdk/input';

export default {
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true,
        },
    },

    data() {
        return {
            visible: false, // TODO is this really needed? :value @input above maybe all we need
            list: [],
            // create account
            displayCreateAccount: false,
            createAccountForm: null,
            newAccountName: null,
            newAccountNameRules: [
                (v) => !!v || 'Display name is required',
                (v) => !v || isValidName(compact(v)) || 'Account name is required',
            ],
            // add account
            addAccountChoices: [],
            addAccountId: null,
            submitFormTimestamp: null,
        };
    },

    computed: {
        id() {
            return this.policyId;
        },
        isFormComplete() {
            // TODO: check that account name is not too similar to existing account name
            return typeof this.newAccountName === 'string' && this.newAccountName.trim().length > 0;
        },
    },

    watch: {
        displayCreateAccount(value) {
            if (value && (this.add || this.create)) {
                this.initAddAccountChoices();
            }
        },
        value(newValue) {
            console.log(`dialog new value for v-model: ${newValue}`);
            if (newValue) {
                setTimeout(() => { this.$nextTick(() => this.$refs.newAccountNameInput.focus()); }, 1);
            }
        },
        // TODO is this 'visible' prop really needed? :value @input above maybe all we need
        visible(value) {
            this.$emit('input', value);
        },
    },

    methods: {
        cancel() {
            this.$emit('cancel');
        },
        onSubmitForm() {
            console.log('onSubmitForm');
            if (!this.isFormComplete) {
                return;
            }
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return; // debounce when both browser form submit & enter button trigger this function, so we only call createAccount once.
            }
            this.submitFormTimestamp = Date.now();
            this.createAccount();
        },
        async createAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { createAccount: true });
                console.log('createAccount');
                const request = {
                    // name: this.name,
                    name: this.newAccountName,
                    // agreeToTerms: this.isAgreeToTermsChecked,
                    // interactionId: this.interactionId, // will be present if account arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the account to an appropriate location after account is created
                };
                const response = await this.$client.user(this.$store.state.session.userId).user.createAccount(request);
                console.log('createAccount response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated) {
                    const account = { id, ...request };
                    this.list.push(account);
                    this.$emit('created-account', account);
                    this.$emit('added-account', account);
                    this.displayCreateAccount = false;
                    this.newAccountName = null;
                } else if (error) {
                    this.error = error;
                } else {
                    this.error = 'Request failed';
                }
                this.$emit('created', { /* todo: new account info */ });
            } catch (err) {
                console.error('failed to create account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { createAccount: false });
            }
        },
    },
};
</script>
