<template>
    <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="teal--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="teal" @click="openCreateAccountDialog">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn>
            <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn icon color="teal" v-on="on">
                        <font-awesome-icon :icon="['fas', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                    </v-btn>
                    <!-- <v-tab v-on="on" class="teal--text">
                        <Avatar :attr="mainProfile" :size="36"/>
                        <font-awesome-icon icon="caret-down" class="pl-2 teal--text" fixed-width size="1x"/>
                    </v-tab> -->
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item-group>
                    <v-list-item :to="{ name: 'service-admin-account-settings' }">
                        <!-- <v-list-item-content> -->
                            <v-list-item-title>Settings</v-list-item-title>
                        <!-- </v-list-item-content> -->
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No accounts yet.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <v-list-item-content class="text-start">
                    <AccountListItem :attr="item" :link="!selectOne"></AccountListItem>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-row justify="center" class="py-5" v-show="displayCreateAccount">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <v-card elevation="4" class="px-10 pb-5 pt-8">
                <v-form v-model="createAccountForm" ref="createAccountFormRef" @submit="validateCreateAccount" onSubmit="return false;" @keyup.enter.native="validateCreateAccount">
                    <div v-if="add">
                        <v-select :items="addAccountChoices" v-model="addAccountId" label="Select an account"></v-select>
                    </div>
                    <div v-if="create">
                        <!--
                        <v-text-field
                            v-model=name
                            label="Name"
                            :rules="nameRules"
                            validate-on-blur
                            color="teal"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="account" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>
                        -->
                        <v-text-field
                            ref="nameInputRef"
                            v-model=newAccountName
                            label="Display Name"
                            :rules="newAccountNameRules"
                            validate-on-blur
                            color="teal"
                            required
                            hint="The name for the account"
                            type="text"
                            outlined
                        >
                        </v-text-field>

                    </div>
                    <v-row justify="center">
                        <v-card-actions>
                            <v-btn elevation="4" class="teal white--text" @click="addAccount" :disabled="!createAccountForm" v-if="add">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Add</span>
                            </v-btn>
                            <v-btn elevation="4" class="teal white--text" @click="createAccount" :disabled="!createAccountForm" v-if="create">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-form>
            </v-card>
            </v-col>
        </v-row>
        <CreateAccountDialog v-model="createAccountDialogVisible" @cancel="createAccountDialogVisible = false" @created="createAccountDialogVisible = false"/>
    </v-card>
</template>

<script>
import AccountListItem from '@/components/AccountListItem.vue';
import CreateAccountDialog from '@/components/service-dashboard/CreateAccountDialog.vue';
import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        AccountListItem,
        CreateAccountDialog,
    },

    props: {
    },

    data() {
        return {
            list: [],
            createAccountDialogVisible: false,
            // create account
            displayCreateAccount: false,
            createAccountForm: null,
            newAccountName: null,
            newAccountNameRules: [
                (v) => !!v || 'Display name is required',
                (v) => !v || isValidName(compact(v)) || 'Account name is required',
            ],
            // add account
            addAccountChoices: [],
            addAccountId: null,
        };
    },

    computed: {
        id() {
            return this.policyId;
        },
        label() {
            if (this.selectOne) {
                return 'Select an account';
            }
            switch (this.list.length) {
            case 0:
                return 'No accounts';
            case 1:
                return '1 account';
            default:
                return `${this.list.length} accounts`;
            }
        },
    },

    watch: {
        displayCreateAccount(value) {
            if (value && (this.add || this.create)) {
                this.initAddAccountChoices();
            }
        },
    },

    methods: {
        async loadAccountList() {
            try {
                this.$store.commit('loading', { loadAccountList: true });
                const response = await this.$client.user(this.$store.state.session.userId).user.getAccountList();
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadAccountList failed', err);
            } finally {
                this.$store.commit('loading', { loadAccountList: false });
            }
        },
        onClickItem(accountId) {
            if (this.selectOne) {
                this.$emit('selected', { accountId });
            }
        },
        openCreateAccountDialog() {
            this.createAccountDialogVisible = true;
        },
        async createAccount() {
            this.error = false;
            console.log('createAccount');
            const request = {
                // name: this.name,
                name: this.newAccountName,
                // agreeToTerms: this.isAgreeToTermsChecked,
                // interactionId: this.interactionId, // will be present if account arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the account to an appropriate location after account is created
            };
            const response = await this.$client.user(this.$store.state.session.userId).user.createAccount(request);
            console.log('createAccount response: %o', response);
            const { isCreated, id, error } = response;
            if (isCreated) {
                const account = { id, ...request };
                this.list.push(account);
                this.$emit('created-account', account);
                this.$emit('added-account', account);
                this.displayCreateAccount = false;
                this.newAccountName = null;
            } else if (error) {
                this.error = error;
            } else {
                this.error = 'Request failed';
            }
        },
        validateCreateAccount() {
            if (this.$refs.createAccountFormRef.validate()) {
                this.createAccount();
            }
        },

        async addAccount() {
            const account = { id: this.addAccountId, label: this.addAccountChoices.find((item) => item.value === this.addAccountId).text };
            this.list.push(account);
            this.$emit('added-account', account);
            this.displayCreateAccount = false;
            this.addAccountId = null;
        },
        async initAddAccountChoices() {
            // create a map of which account ids are already in the list
            const accountMap = {};
            this.list.forEach((item) => {
                accountMap[item.id] = true;
            });
            // when we show the add/create account dialog, load the list of available accounts to add to the policy (list of all accounts less the ones already in the policy)
            const result = await this.$client.user(this.$store.state.session.userId).user.getAccountList();
            console.log(`AccountViewList.vue fetch accounts result: ${JSON.stringify(result)}`);
            if (result && result.list) {
                // filter the results to remove accounts already in the policy, then convert the available accounts to a choice list for the v-select [ { text, value }, ... ]
                this.addAccountChoices = result.list.filter((item) => !accountMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
            }
        },
    },

    mounted() {
        this.loadAccountList();
    },
};
</script>
