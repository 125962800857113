<template>
    <div>
        <template v-if="link">
            <span><router-link :to="{ name: 'realm-dashboard', params: { realmId: id }}">{{ displayName }}</router-link></span>
        </template>
        <template v-if="!link">
            <span>{{ displayName }}</span>
        </template>
        <p class="text-caption">{{ id }} ({{ alias }})</p>
    </div>
</template>

<script>
export default {

    props: {
        // whether the item should link to the account record; a parent component may set this to false if it just wants the user to select an account (and not navigate to it)
        link: {
            type: Boolean,
            default: false,
        },
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        alias() {
            return this.attr.alias;
        },
        displayName() {
            return this.attr.display_name ?? this.attr.alias;
        },
    },

};
</script>
